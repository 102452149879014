div.list-archive {
        LI.nih.ml.index.thread.first {
        	margin-top: 4pt;
        }

        .nih.ml.index.thread.first.subject {
        	font-weight: bold;
        }

        .nih.ml.index.thread.current.subject {
        	font-weight: bold;
        }

        .nih.ml.index.thread.from {
        	font-style: italic;
        }

        P.nih.ml.message.header {
        	margin-bottom: 8pt;
        }

        UL.nih.ml.thread {
        	list-style: none;
        }
        .nih.ml.thread.first {
        	margin-left: 0pt;
        	padding-left: 0pt;
        }
        
        P.nih.credits {
        	font-size: smaller;
        	text-align: right;
        	margin-top: 8pt;
        	margin-right: 8pt;
        	font-style: italic;
        }

        table, th, td {
                border: none;
        }
        tbody tr:nth-child(odd) td,
        tbody tr:nth-child(odd) th {
          background-color: $body-bg;
        }
        
        // dates in thread index
        td[colspan="4"] {
                padding-top: 1.33ex;
        }
        // page number in navigation
        th[colspan="4"] {
                text-align: center;
        }

        h4 {
                font-size: $root-font-size * 1.33;
        }
}
