#sidebar {
    color: rgba(255,255,255,.75);
    background-color: $sidebar-bg-color;
    background-image: linear-gradient(
        to bottom,
        lighten($sidebar-bg-color, 7%),
        darken($sidebar-bg-color, 7%));

    padding: 1rem 1.5rem 1rem;

    .site-title {
        font-family: $root-font-family;
        font-weight: $heavy;
        color: white;
        margin-top: 0;
        margin-bottom: $heading-spacing;
    }

    a {
        color: inherit;
        &:hover {
            text-decoration: underline;
            &.icon {
                text-decoration: none;
            }
        }
        &:hover, &:focus, &.active {
            svg { fill: #fff; }
        }
    }

    svg {
        fill: rgba(255, 255, 255, 0.85);
    }

    ul {
        list-style: none;
        line-height: 1.75;
        padding: 0;
    }
    li {
        list-style: none;
        color: white;
    }

    nav#sidebar-icon-links {
        ul {
            margin-bottom: 0;
        }
        li {
            display: inline-block;
            margin: 0 0.25em;
            font-size: 1.5rem;
        }
    }

    .active {
        font-weight: bold;
    }
}

@media (min-width: $large-breakpoint) {
    #sidebar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: $sidebar-width;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: $section-spacing;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        text-align: left;

        .hamburger-icon {
            display: none;
        }

        .stretch {
            flex: auto;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        .site-title {
            font-size: 3.25rem;
        }

        p.lead {
            display: block;
        }
    }

    main {
        padding-left: $sidebar-width;
    }

    @media (max-height: 665px) {
        p.site-version, nav#sidebar-icon-links {
            display: none;
        }
    }
    @media (max-height: 574px) {
        #sidebar p.lead {
            display: none;
        }
    }
    @media (max-height: 445px) {
        p.copyright {
            display: none;
        }
    }
}

@media (max-width: $large-breakpoint - 1) {
    #sidebar {
        .site-title {
            margin-bottom: 0;
        }

        p.lead, p.site-version, p.copyright, nav#sidebar-icon-links {
            display: none;
        }

        ul {
            margin-top: 1em;
            line-height: 3;
        }

        li {
            border-top: 1px solid rgba(255, 255, 255, .35);
        }
        a:last-child > li {
            border-bottom: 1px solid rgba(255, 255, 255, .35);
        }
    }


    .title-line {
        display: flex;
        flex-direction: row;
    }
    .site-title {
        flex: auto;
        display: inline;
    }
}
