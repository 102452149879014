$root-font-family: Merriweather;
$sans-font-family: MerriweatherSans;
$mono-font-family: PTMono;
$code-font-family: $mono-font-family;
$large-font-size: 1rem;
$max-content-width: 50rem;

@import "merriweather";
@import "ptmono";
@import "hydeout";
@import "list-archive";

@import "mandoc";

p.copyright {
        font-size: small;
}

h1.Sh {
  font-size: 1.5rem;
}

table.Nm {
        font-size: 100%;
        border: 0px;
}

table.Nm tr {
        vertical-align: baseline;
        border: 0px;
}

table.Nm tbody td {
        border: 0px;
        background-color: $body-bg;
}

code, tt, pre {
        font-family: $mono-font-family;
}

