dl.Bl-tag {
    margin-left: 10.2ex;

}
dt.It-tag {
    float: left;
    margin-top: 0ex;
    margin-left: -10.2ex;
    padding-right: 2ex;
    vertical-align: top;
}
dd.It-tag {
    clear: right;
    width: 100%;
    margin-top: 0ex;
    margin-left: 0ex;
    vertical-align: top;
    overflow: auto;
}
dl.Bl-compact > dt {
    margin-top: 0ex;
}
