$light: 200;
$heavy: 900;

@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-Light.otf") format("opentype");
  font-weight: $light;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-LightItalic.otf") format("opentype");
  font-weight: $light;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-Black.otf") format("opentype");
  font-weight: $heavy;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'Merriweather';
  src: url("/assets/fonts/Merriweather-BlackItalic.otf") format("opentype");
  font-weight: $heavy;
  font-style: italic;
  font-variant: normal;
}

@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-Light.otf") format("opentype");
  font-weight: $light;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-LightItalic.otf") format("opentype");
  font-weight: $light;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-ExtraBold.otf") format("opentype");
  font-weight: $heavy;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: 'MerriweatherSans';
  src: url("/assets/fonts/MerriweatherSans-ExtraBoldItalic.otf") format("opentype");
  font-weight: $heavy;
  font-style: italic;
  font-variant: normal;
}
